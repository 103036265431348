import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"

const BunkerSales = () => {
    return (
        <Layout>
            <Seo title="Marine Fuels Sales" />
            <div className="container py-3 py-lg-4">
                <div className="row g-0 mb-3">
                    <div className="col border-bottom border-1 border-dark">
                        <h1>Marine Fuels Sales</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="card mb-3 border-0">
                        <div className="row gx-3">
                            <div className="col-md-6 order-2 ">
                                <div className="card-body services-text d-flex flex-column">
                                    <p className="card-text">
                                        With one of the most experienced trading teams in the market spread across several key
                                        timezones, we understand what bunker trading means to you and every team member knows how to
                                        add value to your business, 24/7. With unrivalled flexibility and a good old fashioned customer
                                        service ethos, we are here to help.
                                    </p>
                                    <p>
                                        Our trading team currently consists of trading desks across five international timezones, in
                                        Rotterdam, London, St. Petersburg, Geneva, Hamburg and Singapore, with a total of twenty
                                        traders and looking to add more dedicated team members. All our traders across the globe
                                        specialize in the back-to-back trading model offering Delta Corp Energy Logistics’s credit
                                        terms, terms and conditions and carefully chosen reputable third-party supplier.
                                    </p>
                                    <p>
                                        Our credit policy allows us to operate effectively and our global reach with special supplier
                                        relationships enables Delta to provide you the best service in the market.
                                    </p>
                                    <p>
                                        Delta remain dynamic and adaptable to your needs. Also offering the traditional brokerage model
                                        which offers you more transparency on your marine fuel requirements and purchasing. With both
                                        models, Delta provides the most efficient operational support and claims management solutions
                                        which remain professional and personalized.
                                    </p>
                                    <p>
                                        Delta has all the resources it requires being part of the larger Delta Corp Group and its
                                        strong backing.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6 order-1 order-md-2 d-flex justify-content-center ">
                                <StaticImage
                                    transformOptions={{ cropFocus: "attention" }}
                                    alt="Sales team"
                                    src="../../../images/service/bat.jpg"
                                    aspectRatio={16 / 9}
                                    width={640}
                                    layout="fixed"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default BunkerSales
